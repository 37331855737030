import { XctBaseType } from '../types/xctBaseType';

import { TokenClass } from '../types/token_class';

export enum TransactionAccountedState {
  // Not done yet
  NO = 0,
  IN_PROGRESS,
  DONE,
  // in review for payout, from here go to done or approved
  PAYOUT_REQUEST,
  // funds send out for payout
  APPROVED,
  // rejected Funds held, if refunded, the refund is a sperate transaction
  REJECTED
}

export enum TransactionType {
  XSACT = 0,     // between 2 xsact accounts
  STRIPE_CC = 1, // fund account with CC
  BANK_OUT = 2,  // Transfer out to bank
  RETURN = 3,    // Refund or Return of funds
  SUBSCRIPTION_READ = 4,  // Subscription read that was already paid
  TEST_XSACT = 5,
  TEST_DEPOSIT_IN = 6,
  TEST_BANK_OUT = 7,
  TEST_RETURN = 8,
  TESTSUBSCRIPTION_READ = 9,
}

export interface TransactionInstance {
    buyer: number
    uid: string
    recipient: number
    from_timestamp: number
    to_timestamp: number
    tokens: string[]
    transactions: Transaction[]
    count: number
  }

export class Transaction extends XctBaseType {
  accounted: string = null; // smk: returned by API, not sure what this is
  affiliate_id: number = null;
  affiliate_token_cents: number = null;
  alg: string = null;
  buyer_balance: number = null;
  buyer_first_name: string = null;
  buyer_ip: string = null;
  buyer_last_name: string = null;
  buyer: number = null; // 64 bit ID of buyer,
  buyer_currency_class = 'PROD';
  buyer_currency_cents: number = null;
  commission: number = null;
  date_out: string = null; // output on website
  hmac: string = null;
  id: number = null;
  item: string = null;
  jwt: any = null; // JSON Web token passed back
  meta: any = null; // smk: returned by API
  method: string = null;
  recipient_first_name: string = null;  // if recipient is a User
  recipient_last_name: string = null;
  recipient_org: string = null;  // If recipient is a group/publisher
  recipient: string = null; // ID of who recieves funds
  shard: string = null; // smk: returned by API, not sure what this is
  timestamp: number = null;
  title: string = null; // smk: returned by API, not sure what this is
  token_cents: number = null; // token_cents/100 = credits to display on the page
  token_class: TokenClass = null;
  trans_type: TransactionType = null; // smk: returned by API, not sure what this is
  uid: string = null;
  url: string = null; // url of content that was purchased/viewed as a result of this transaction
  wantsToSubscribe = false;

  constructor(defaultsHash: any = {}) {
    super();
    // let newObject = this.initializeFromDefaultsHash(defaultsHash);
    this.initializeFromDefaultsHash(defaultsHash);
  }

  // Probably shouldn't be calling this on a transaction that doesn't have credits? Not sure.
  getCredits(): any {
    if (this.token_cents) {
      return this.token_cents / 100;
    } else {
      console.error(`Trying to 'getCredits()', but there is no 'token_cents' value? (${this.token_cents})`);
      return '';
    }
  }

  // token_cents
  getBuyerBalance_USD(): any {
    if (this.buyer_balance) {
      return this.buyer_balance / 100;
    } else {
      console.error(`Trying to 'getBuyerBalance_USD()', but there is no 'buyer_balance' value? (${this.buyer_balance})`);
      return '';
    }
  }

  getTokens_USD(): any {
    return this.getCredits();
  }

  isAccountInflow(): boolean {
    let result = false;
    if (this.trans_type === TransactionType.STRIPE_CC) {
      result = true;
    }
    return result;
  }

  isAccountOutflow(): boolean {
    let result = false;
    if (this.trans_type === TransactionType.BANK_OUT || this.trans_type === TransactionType.RETURN) {
      result = true;
    }
    return result;
  }

}

export const TransactionErrorsObject = [
    {
        code: 'INSUFFICIENT_FUNDS',
        message: 'Insufficient funds in your account (INSUFFICIENT_FUNDS)'
    },
    {
        code: 'NO_VALID_CARD',
        message: 'No valid credit card on your account (NO_VALID_CARD)'
    },
    {
        code: 'INVALID_USER',
        message: 'Invalid user account (INVALID_USER)'
    },
    {
        code: 'INVALID_CAPTCHA',
        message: 'Captcha does not match (INVALID_CAPTCHA)'
    },
    {
        code: 'INVALID_EMAIL',
        message: 'Invalid email address on account (INVALID_EMAIL)'
    },
    {
        code: 'INVALID_INPUT',
        message: 'Invalid purchase code. Please try purchasing again from the publisher website. (INVALID_INPUT)'
    },
    {
        code: 'INVALID_AFFILIATE',
        message: 'Invalid affiliate code (INVALID_AFFILIATE)'
    },
    {
        code: 'INVALID_SUBSCRIPTION_PLAN',
        message: 'Invalid subscription plan on your account (INVALID_SUBSCRIPTION_PLAN)'
    },
    {
        code: 'INVALID_SUBSCRIBER',
        message: 'Invalid subscriber account (INVALID_SUBSCRIBER)'
    },
    {
        code: 'INVALID_TOKEN_CLASS',
        message: 'Invalid purchase token. Please try again (INVALID_TOKEN_CLASS)'
    },
    {
        code: 'ACCESS_DENIED',
        message: 'Access denied.'
    },
    {
        code: 'LOCK_HELD',
        message: 'Lock held.'
    },
    {
        code: 'QUERY_ERROR',
        message: 'Query error.'
    },
    {
        code: 'ALREADY_EXISTS',
        message: 'Already Exists.'
    },
    {
        code: 'DOES_NOT_EXIST',
        message: 'Does not exist.'
    },
    {
        code: 'KEY_MISMATCH',
        message: 'Key mismatch.'
    },
    {
        code: 'HMAC_FAILED',
        message: 'Hmac failed.'
    },
    {
        code: 'CC_DECLINED',
        message: 'Your credit card was declined (CC_DECLINED)'
    },
    {
        code: 'FAILED_2FA',
        message: '2nd factor authentication failure. Please try again or reset your account password'
    },
    {
        code: 'STRIPE_ERROR',
        message: 'STRIPE_ERROR'
    },
    {
        code: 'TIMEOUT',
        message: 'Purchase timed out. Please try again. (TIMEOUT)'
    },
    {
        code: 'EMAIL_NOT_VERIFIED',
        message: 'Email address not verified (EMAIL_NOT_VERIFIED)'
    },
    {
        code: 'RATE_LIMITED',
        message: 'Too many requests. Please try again later. (RATE_LIMITED)'
    },
    {
        code: 'CRYPTO_ERROR',
        message: 'Crypto error.'
    },
    {
        code: 'BUYER_NOT_FOUND',
        message: 'Could not determine the purchaser account. (BUYER_NOT_FOUND)'
    },
    {
        code: 'RECIPIENT_NOT_FOUND',
        message: 'Could not determine recipient (RECIPIENT_NOT_FOUND)'
    },
    {
        code: 'invalid_number',
        message: 'The card number is not a valid credit card number.'
    },
    {
        code: 'INVALID_CURRENT_PASSWORD',
        message: 'Current password is wrong. Try again.'
    },
    {
        code: 'CC_INCORRECT_CVC',
        message: 'Incorrect CVC code. Please try again..'
    },
    {
        code: 'CC_INVALID_CVC',
        message: 'Invalid CVC code. Please try again.'
    },
    {
        code: 'CC_PROCESSING_ERROR',
        message: 'Processing error. Please try again.'
    },
    {
        code: 'CC_INCORRECT_NUMBER',
        message: 'Incorrect number. Please try again.'
    },
    {
        code: 'CC_INVALID_EXP_MONTH',
        message: 'Invalid expired month date. Please try again.'
    },
    {
        code: 'CC_INVALID_EXP_YEAR',
        message: 'Invalid expired year date. Please try again.'
    },
    {
        code: 'CC_INVALID_NUMBER',
        message: 'Invalid number. Please try again.'
    }
];
