import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  Router
} from '@angular/router';

import { OAuth2Client } from '../types/oauth2_client';



@Injectable()
export class OAuth2Service {

  constructor(private http: HttpClient, private router: Router) {

  }


  getClientInfo(client_id: number): Promise<void | OAuth2Client> {
    return this.http.get('/api/oauth2/client/' + client_id + '/info')
      .toPromise()
      .then((response: OAuth2Client) => {
        // debug('got client info response', response);

         if (response && response.client_id) {
          const client: OAuth2Client = response;
          return client;
        } else {
          return new OAuth2Client();
        }

      })
      .catch((err: HttpErrorResponse) => {
        console.error('Error fetching oauth2 client info', err);
        throw(err);
      });
  }

  getOAuthCode(client_id: number, redirect_uri: string, scope: string, state: string): Promise<Response> {

    const params = new HttpParams()
      .set('client_id', client_id.toString())
      .set('redirect_uri', redirect_uri)
      .set('scope', scope)
      .set('state', state);


    return this.http.get('/api/oauth2/code', { params })
      .toPromise()
      .then((response: any) =>
        // debug('got code response', response);
          response
      )
      .catch((err: HttpErrorResponse) => {
        console.error('Error getOAuthCode', err);
        throw(err);
      });
  }
}
