import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { StateService, CountryService, ExpDateRegEx, StripeService, User } from '@shared';
import { Stripe } from '@stripe/stripe-js';

@Component({
  selector: 'app-xsact-credit-card',
  templateUrl: 'xsact-credit-card.component.html',
  styleUrls: ['xsact-credit-card.component.scss'],
  providers: [CountryService, StateService],
})
export class XsactCreditCardComponent implements OnChanges, OnInit {
  @Input() set user(user: User | undefined) {
    if (user) {
      if (user.created_country) {
        this.setDefaultCountry(user.created_country);
      }
    }
    this.user_p = user;
  }
  get user() {
    return this.user_p;
  }
  @Output() addedCard = new EventEmitter<any>();
  @Output() validStatus = new EventEmitter<boolean>();
  @ViewChild('creditCardElement') creditCardElRef: ElementRef;
  newCard: any;
  messages: any;
  creditCardForm: FormGroup;
  creditCard: any;
  errorMessages: string[] = [];
  countries: { code: string; region: string; name: string; selected?: boolean }[] = [];
  debonce: any;
  focusedFormInput: any;
  selectorClicked = false;
  stripe: Stripe;

  private user_p: User;

  constructor(
    private cService: CountryService,
    public stateService: StateService,
    private stripeService: StripeService,
    private fb: FormBuilder
  ) {
    this.countries = this.cService.countries;
    this.setCreditCardForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.newCard) {
      this.updateFields();
    }
  }

  ngOnInit() {
    this.stripeService.initStripe().then((stripe) => {
      this.stripe = stripe;

      const elements = this.stripe.elements({
        locale: 'en',
      });

      this.creditCard = elements.create('card', {
        iconStyle: 'solid',
        hidePostalCode: true,
        style: {
          base: {
            'iconColor': '#8898AA',
            'color': 'black',
            'lineHeight': '36px',
            'fontWeight': 300,
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSize': '19px',
            '::placeholder': {
              color: '#8898AA',
            },
          },
          invalid: {
            iconColor: '#e85746',
            color: '#e85746',
          },
        },
        classes: {
          focus: 'is-focused',
          empty: 'is-empty',
        },
      });

      this.creditCard.mount('#card-element');

      this.creditCard.on('change', () => {
        this.validateCreditCardForm();
      });
    });
    if (!this.user) {
      this.setDefaultCountry('US');
    }
  }

  onInputEvent(event: any) {
    const eventType = event.type;
    const target = event.target;

    switch (eventType) {
      case 'click':
        this.focusedFormInput = target;
        break;
      case 'focus':
        target.classList.add('is-focused');
        this.focusedFormInput = target;
        break;
      case 'blur':
        target.classList.remove('is-focused');
        this.validateCreditCardForm();
        break;
      case 'keyup':
        if (target.value.length === 0) {
          target.classList.add('is-empty');
        } else {
          target.classList.remove('is-empty');
        }

        clearTimeout(this.debonce);
        this.debonce = setTimeout(() => {
          this.validateCreditCardForm();
        }, 300);
        break;
    }
  }

  setOutcome(result: any) {
    if (result.token) {
      if (this.creditCardForm.valid) {
        this.setFormMessage('success');
        this.stripeService.setCardValidationStatus('valid');
        this.addedCard.emit({
          cardToken: result,
          cardStripeElement: this.creditCard,
        });
        this.validStatus.emit(true);
      } else {
        this.setFormMessage('error', 'Payment form is invalid.');
        this.stripeService.setCardValidationStatus('invalid');
        this.validStatus.emit(false);
      }
    } else if (result.error) {
      if (this.creditCardForm.invalid) {
        this.setFormMessage('error', 'Payment form is invalid.');
      } else {
        this.setFormMessage('error', result.error.message);
      }
      this.stripeService.setCardValidationStatus('invalid');
      this.validStatus.emit(false);
    }

    if (this.focusedFormInput) {
      this.focusedFormInput.focus();
      setTimeout(() => {
        this.focusedFormInput = null;
      }, 100);
    }
  }

  setCreditCardForm() {
    this.creditCardForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      zip: ['', [Validators.required, Validators.minLength(3)]],
      country: ['', [Validators.required]],
    });
  }

  validateCreditCardForm() {
    this.validateAllFormFields(this.creditCardForm);
    const creditCardDetails = {
      name: this.creditCardForm.get('name').value,
      address_line1: this.creditCardForm.get('address').value,
      address_city: this.creditCardForm.get('city').value,
      address_state: this.creditCardForm.get('state').value,
      address_zip: this.creditCardForm.get('zip').value,
      address_country: this.creditCardForm.get('country').value,
    };
    this.stripe.createToken(this.creditCard, creditCardDetails).then(this.setOutcome.bind(this));
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  updateFields() {
    const expMatch = this.newCard.exp_date.match(ExpDateRegEx);
    if (expMatch) {
      this.newCard.exp_month = parseInt(expMatch[1], 10);
      this.newCard.exp_year = parseInt(expMatch[2], 10);
    } else {
      this.newCard.exp_month = 0;
      this.newCard.exp_year = 0;
    }

    this.newCard.last4 = this.newCard.number ? this.newCard.number.toString().slice(-4) : '';
  }

  setFormMessage(type: string, message?: string) {
    this.errorMessages.push(message);

    const successElement = document.querySelector('.credit-card-wrapper .success');
    const errorElement = document.querySelector('.credit-card-wrapper .error');

    successElement.classList.remove('visible');
    errorElement.classList.remove('visible');

    (type === 'success' ? successElement : errorElement).classList.add('visible');

    if (type === 'error') {
      errorElement.querySelector('.message').textContent = message;
    }
  }

  clearFormErrors() {
    this.errorMessages = [];

    const successElement = document.querySelector('.credit-card-wrapper .success');
    const errorElement = document.querySelector('.credit-card-wrapper .error');

    successElement.classList.remove('visible');
    errorElement.classList.remove('visible');
  }

  setDefaultCountry(countryCode: string) {
    for (const c of this.countries) {
      if (c.code === countryCode) {
        c.selected = true;
        this.creditCardForm.get('country').setValue(countryCode);
      } else {
        c.selected = false;
      }
    }
  }

  changeCountry() {
    this.creditCardForm.get('state').setValue('');
    this.validateCreditCardForm();
  }

  changeState() {
    this.validateCreditCardForm();
  }
}
