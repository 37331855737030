import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AccountFunds } from '../types/account_funds';
import { Subscription } from '../types/subscription';
import { GiftSubscription } from './../types/gift_subscription';
import { User } from './user';

const baseUrl = '/api/user/';

export interface PublisherSettings {
  banner_image: string;
  org: string;
  id?: string;
  style: {
    sidebarTitle: string;
    sidebarText: string;
    bannerBgColor: string;
  };
  profile_image: string;
  website: string;
}

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  authError(error: HttpErrorResponse) {
    console.log('UserServie authentication ERROR');
    console.log(error);
  }

  respOK() {
    console.log('respOK');
  }

  // Fill with current user data
  getCurrentUser(): Promise<User> {
    return this.http.get<User>(baseUrl + 'profile').toPromise();
  }

  getCurUserFunds(): Promise<AccountFunds> {
    return this.http.get<AccountFunds>(baseUrl + 'tokens').toPromise();
  }

  setTestTokens<T>(
    num_tokens: number,
    data: T,
    set_tokens_ok_cb: (data: T) => any,
    set_tokens_err_cb: (data: T, error: Response) => any
  ) {
    const params = new HttpParams().set('value', num_tokens.toString());

    this.http
      .get(baseUrl + 'set/test/tokens', {
        params,
      })
      .subscribe(
        (res) => {
          //        console.log(res);
        },
        (err) => set_tokens_err_cb(data, err),
        () => {
          set_tokens_ok_cb(data);
        }
      );

    return;
  }

  forgotPassword(email: string): Promise<any> {
    const payload = {
      email,
    };

    return this.http.post(baseUrl + 'password/forgot', payload).toPromise();
  }

  resetPassword(new_password: string, token: string): Promise<any> {
    const payload = {
      password: new_password,
      token,
    };
    console.log('sending post');

    return this.http.post(baseUrl + 'password/reset', payload).toPromise();
  }

  isAuthenticated(): Promise<any> {
    return this.http.get(baseUrl + 'authenticated').toPromise();
  }

  deleteAccount(): Promise<any> {
    return this.http.delete(baseUrl + 'profile').toPromise();
  }

  get2FaCode(): Promise<any> {
    return this.http.get(baseUrl + '2fa/totp/init').toPromise();
  }

  enable2Fa(token: string): Promise<any> {
    const payload = {
      token,
    };

    return this.http.post(baseUrl + '2fa/totp/set', payload).toPromise();
  }

  disable2Fa(): Promise<any> {
    return this.http.delete(baseUrl + '2fa/totp').toPromise();
  }

  getPublisherInfo(pid: number) {
    const url = `/api/public/group/${pid}/profile`;

    return this.http.get<PublisherSettings>(url).toPromise();
  }

  getPublisherNames(pids: Array<number>): Promise<any> {
    const url = '/api/public/account/profiles';

    return this.http.post(url, { id: pids.join(',') }).toPromise();
  }

  getGroupSubscriptions(groupId: number): Promise<Subscription[]> {
    const url = '/api/group/' + groupId.toString() + '/subscription/plans';
    return this.http.get<Subscription[]>(url).toPromise();
  }

  deleteSingleAuthPubs(oneToDelete?: number): Promise<any> {
    let url = '/api/user/oneclick/';
    if (oneToDelete) {
      url += encodeURIComponent(oneToDelete.toString());
    } else {
      url += 'all';
    }

    return this.http.delete(url).toPromise();
  }

  getUserSubscriptions() {
    return this.http
      .get<{
        subscriptions: Subscription[];
        gifts: GiftSubscription[];
      }>(baseUrl + 'subscriptions')
      .toPromise();
  }

  buySubscription(
    groupId: number,
    plan: string,
    auto_renew: boolean,
    redemption_code?: string
  ): Promise<Subscription> {
    const data = {
      auto_renew,
      redemption_code,
    };

    return this.http
      .put<Subscription>(
        baseUrl + 'subscription/' + encodeURIComponent(groupId.toString()) + '/' + plan,
        data
      )
      .toPromise();
  }

  deleteUserSubscription(groupId: number): Promise<Subscription> {
    return this.http
      .delete<Subscription>(baseUrl + 'subscription/' + encodeURIComponent(groupId.toString()))
      .toPromise();
  }

  sendGiftSubscription(giftForm: any): Promise<any> {
    const data = Object.assign(
      {},
      {
        gift: giftForm,
      }
    );

    return this.http.post(baseUrl + 'gift/subscription/send', data, {}).toPromise();
  }

  getGiftSubscription(giftId: number | string): Promise<any> {
    return this.http.get(baseUrl + 'gift/subscription/info/' + giftId).toPromise();
  }

  getSubscriptionGifts(): Promise<any> {
    return this.http.get(baseUrl + 'gift/subscription/list').toPromise();
  }

  acceptGiftSubscription(giftId: number | string): Promise<any> {
    return this.http.put(baseUrl + 'gift/subscription/redeem/' + giftId, {}).toPromise();
  }

  declineGiftSubscription(giftId: number | string): Promise<any> {
    return this.http.put(baseUrl + 'gift/subscription/decline/' + giftId, {}).toPromise();
  }

  public async getPublisherId(route: ActivatedRouteSnapshot): Promise<number> {
    const user = await this.getCurrentUser();
    const userGroups = user.groups;
    const publisherIdUrlParam =
      route && route.params && route.params.publisherId
        ? parseInt(route.params.publisherId, 10)
        : null;

    console.log('userGroups: ', userGroups, 'publisherIdUrlParam: ', publisherIdUrlParam);

    if (user.staff && publisherIdUrlParam) {
      return publisherIdUrlParam;
    } else if (userGroups && userGroups.length && publisherIdUrlParam) {
      const searchForGroup = userGroups.filter((g) => g === publisherIdUrlParam).length;
      if (searchForGroup) {
        console.log('route param equal user group');
        return publisherIdUrlParam;
      } else {
        console.log('route param not found in user groups, returning user group id');
        return userGroups[0];
      }
    } else if (userGroups && userGroups.length && !publisherIdUrlParam) {
      console.log('route param doesnt exist, returning user group id');
      return userGroups[0];
    } else {
      console.log('no url param, no group id');
      return null;
    }
  }
}
