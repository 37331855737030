import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Currency, Subscription } from '@shared/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenClass, TokenClassKeyValue } from '../types/token_class';
import { Transaction, TransactionAccountedState, TransactionInstance } from './transaction';
import { User } from './user';

const adminBaseUrl = '/api/admin/';
const transactBaseUrl = '/api/transact/';
const utilBaseUrl = '/api/util/';

export enum AuthComponentState {
  INIT,
  LOGIN,
  LOGIN_2FA,
  LOGIN_2FA_RECOVER,
  REGISTER,
  SU_CREDS_PAYM,
  SU_BILLING_ADDY,
  EXISTING_CARDS,
  USE_SAVED,
  CONFIRMATION,
  CREDIT_CARD,
  CC_ADDRESS,
  EDIT_PROFILE,
  PAY,
  ALL_SET,
  WANTS_SUBSCRIPTION,
  BAD_TOKEN,
  DONATION,
}

export class TransactionQuery extends HttpParams {
  recipient_id: string;
  buyer_id: string;
  from_timestamp: number;
  to_timestamp: number;
  start: number;
  limit: number;
  sort: string[];
  trans_type: number;
  affiliate_id: number;
  affiliate_token_cents: number;

  constructor() {
    super();
    this.recipient_id = '';
    this.buyer_id = '';
    this.from_timestamp = 0;
    this.to_timestamp = 0;
    this.start = 0;
    this.limit = 0;
    this.sort = [];
    this.trans_type = 0;
    this.affiliate_id = 0;
    this.affiliate_token_cents = 0;
  }
}

export type SubscriptionPlanPreview = Partial<Subscription> & {
  has_sufficient_funds: boolean;
  funds_needed: number;
  funds_needed_class: Currency;
};

export interface TransactionPreview {
  has_subscription: boolean;
  subscription_expires: number;
  has_sufficient_funds: boolean;
  purchase_currency: PaymentAmount & {
    needed: number;
  };
  source_currency: PaymentAmount & {
    convert: number;
    insufficient: number;
  };
  buyer: Partial<User>;
  recipient: Partial<User>;
  subscription_plans: SubscriptionPlanPreview[];
}

export interface PaymentAmount {
  currency: string;
  total: number;
}

@Injectable()
export class TransactionService {
  message: string;
  id: number;
  error_str: string;
  url: string;
  hmac: string;

  constructor(private http: HttpClient) {}

  static CurrencyStrToTokenClass(currency: string): TokenClass {
    switch (currency.toUpperCase().trim()) {
      case 'USD':
        return TokenClass.PROD;
      case 'PROD':
        return TokenClass.PROD;
      case 'TEST':
        return TokenClass.TEST;
      case 'CAD':
        return TokenClass.CAD;
      case 'EUR':
        return TokenClass.EUR;
      case 'GBP':
        return TokenClass.GBP;
      case 'JPY':
        return TokenClass.JPY;

      default:
        console.warn('CurrencyStrToTokenClass currency', currency);
        return TokenClass.PROD;
    }
  }

  static TokenClassToCurrency(token_class: TokenClass): string {
    switch (token_class) {
      case TokenClass.PROD:
        return 'USD';
      case TokenClass.TEST:
        return 'TEST';
      case TokenClass.CAD:
        return 'CAD';
      case TokenClass.EUR:
        return 'EUR';
      case TokenClass.GBP:
        return 'GBP';
      case TokenClass.JPY:
        return 'JPY';
      default:
        console.warn('TokenClassToCurrency not set', token_class);
        return 'USD';
    }
  }

  authError(error: Response) {
    console.log('authentication ERROR');
    console.log(error);
  }

  respOK() {
    console.log('respOK');
  }

  checkTransactionStatus(recipient: string, uid: string) {
    const requestUrl = transactBaseUrl + `by-recipient-uid/${recipient}/${uid}`
    return this.http.get<TransactionInstance>(requestUrl).toPromise();
  }

  convertTransactionCurrency(from: TokenClass, to: TokenClass, amount: number): Observable<number> {
    const fromParam: string = from === TokenClass.PROD ? 'USD' : from;
    const toParam: string = to === TokenClass.PROD ? 'USD' : to;
    if (fromParam === toParam) {
      return of(amount);
    }

    const requestUrl = utilBaseUrl + `exchange/rate/quote/${fromParam}/${toParam}`;
    return this.http.get<any>(requestUrl).pipe(
      map((resp) => {
        if (resp.high) {
          return Math.ceil(resp.high * amount);
        }
        return amount;
      })
    );
  }

  getTransPreview(jwt: string): Promise<TransactionPreview> {
    const requestUrl = transactBaseUrl + 'preview?t=' + jwt;
    return this.http.post<any>(requestUrl, {}).toPromise();
  }

  getPayoutPending(): Promise<Transaction[]> {
    const requestUrl = adminBaseUrl + 'transactions/review/pending';
    return this.http.get<Transaction[]>(requestUrl).toPromise();
  }

  getPayoutApproved(): Promise<Transaction[]> {
    const requestUrl = adminBaseUrl + 'transactions/review/approved';
    return this.http.get<Transaction[]>(requestUrl).toPromise();
  }
  getPayoutRejected(): Promise<Transaction[]> {
    const requestUrl = adminBaseUrl + 'transactions/review/rejected';
    return this.http.get<Transaction[]>(requestUrl).toPromise();
  }

  changePayoutRequestState(id: number, notes: string, state: number): Promise<boolean> {
    const requestUrl: string = adminBaseUrl + 'transactions/pending/review';
    let requestState = '';

    switch (state) {
      case TransactionAccountedState.APPROVED:
        requestState = 'APPROVED';
        break;
      case TransactionAccountedState.REJECTED:
        requestState = 'REJECTED';
        break;
    }

    const data = {
      id,
      notes,
      state: requestState,
    };

    return this.http.post<boolean>(requestUrl, data).toPromise();
  }

  getTransactionList(qry: TransactionQuery): Promise<Transaction[]> {
    let params = new HttpParams()
      .set('start', qry.start.toString())
      .set('sort', qry.sort.toString());

    if (qry.recipient_id) {
      params = params.set('recipient_id', qry.recipient_id);
    } else if (qry.buyer_id) {
      params = params.set('buyer_id', qry.buyer_id);
    }
    if (qry.affiliate_id) {
      params = params.set('affiliate', qry.affiliate_id.toString());
    }

    if (qry.from_timestamp !== 0) {
      params = params.set('from_timestamp', qry.from_timestamp.toString());
    }
    if (qry.to_timestamp !== 0) {
      params = params.set('to_timestamp', qry.to_timestamp.toString());
    }
    if (qry.limit !== 0) {
      params = params.set('limit', qry.limit.toString());
    }
    if (qry.trans_type !== undefined) {
      // can be zero
      params = params.set('trans_type', qry.trans_type.toString());
    }

    return this.http.get<Transaction[]>(transactBaseUrl + 'list', { params }).toPromise();
  }

  getUserFundsAdmin(userId: number) {
    return this.http.get<TokenClassKeyValue>(`${adminBaseUrl}account/${userId}/tokens`).toPromise();
  }

  authIdentity(publisherId: number) {
    return this.http
      .get<{
        data: { account_id: number; first_name: string; last_name: string; email1: string };
        jwt: string;
      }>(`/api/user/identity/self-verify/${publisherId}/jwt`)
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  authTrans(jwt: string, singleAuth?: boolean) {
    const params = new HttpParams()
      .set('t', jwt)
      .set('enable_one_click', singleAuth ? 'true' : 'false');

    const url = transactBaseUrl + 'auth';
    return this.http.get<Transaction>(url, { params }).toPromise();
  }

  refundRequest(transactionId: string | number, reason: string): Promise<any> {
    const body = {
      reason,
    };

    return this.http
      .put(`/api/transact/refund/by-id/${transactionId}`, body)
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }
}
